import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import FloatingWhatsApp from './components/FloatingWhatsApp';
import CookieConsent from './components/CookieConsent';


const Home = lazy(() => import('./Pages/Home'));
const Contacto = lazy(() => import('./Pages/Contacto'));
const Nosotros = lazy(() => import('./Pages/Nosotros'));
const Experience = lazy(() => import('./Pages/Experience'));
const Automaticas = lazy(() => import('./Pages/Automaticas'));
const Manuales = lazy(() => import('./Pages/Manuales')); 
const Seguros = lazy(() => import('./Pages/Seguros'));
const Rentar = lazy(() => import('./Pages/Rentar'));
const Reservation = lazy(() => import('./Pages/Reservation'));
const Requisitos4Rent = lazy(() => import('./Pages/Requisitos4Rent'));
const Condiciones4Rent = lazy(() => import('./Pages/Condiciones4Rent'));
const Blog = lazy(() => import('./Pages/Blog'));  
const NotFoundPage = lazy(() => import('./Pages/NotFoundPage'));
const VariedadMotos = lazy(() => import('./Pages/VariedadMotos'));
const Asesores = lazy(() => import('./Pages/Asesores'));
const Motocicletas = lazy(() => import('./Pages/Motocicletas'));
const Nmax = lazy(() => import('./Pages/motos_A/Nmax'));
const Fz250 = lazy(() => import('./Pages/motos_A/Fz250'));
const Dominar400 = lazy(() => import('./Pages/motos_A/Dominar400'));
const Xre300 = lazy(() => import('./Pages/motos_A/Xre300'));
const Xtz150 = lazy(() => import('./Pages/motos_A/Xtz150'));
const Xpulse200 = lazy(() => import('./Pages/motos_A/Xpulse200'));
const Pcx150 = lazy(() => import('./Pages/motos_A/Pcx150'));
const Gixxer250 = lazy(() => import('./Pages/motos_A/Gixxer250'));
const Fz150 = lazy(() => import('./Pages/motos_A/Fz150'));
const Bws125 = lazy(() => import('./Pages/motos_A/Bws125'));
const Girardod = lazy(() => import('./Pages/Spaces/Girardod'));
const Parques = lazy(() => import('./Pages/Spaces/Parques'));
const Laguna = lazy(() => import('./Pages/Spaces/Laguna'));
const Chiflón = lazy(() => import('./Pages/Spaces/Chiflón'));
const Centro = lazy(() => import('./Pages/Spaces/Centro'));
const Jardin = lazy(() => import('./Pages/Spaces/Jardin'));
const KymcoAgility125 = lazy(() => import('./Pages/motos_A/KymcoAgility125'));
const Ruiz = lazy(() => import('./Pages/Spaces/Ruiz'));
const Villa = lazy(() => import('./Pages/Spaces/Villa'));
const Caño = lazy(() => import('./Pages/Spaces/Caño'));
const KymcoTwist125 = lazy(() => import('./Pages/motos_A/KymcoTwist125'));


function App() {


  return (
    <Router>
      <div>
        <Navbar />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home phoneNumber="+573154573482" />} />
            <Route path="/motocicletas" element={<Motocicletas phoneNumbertow="3154573482" />} />
            <Route path="/contacto" element={<Contacto phoneNumberone="3154573482" />} />
            <Route path="/nosotros" element={<Nosotros />} />
            <Route path="/manuales" element={<Manuales />} />
            <Route path="/automaticas" element={<Automaticas />} />
            <Route path="/experience" element={<Experience />} />
            <Route path="/seguros" element={<Seguros />} />
            <Route path="/rentar" element={<Rentar />} />
            <Route path="/nmax" element={<Nmax />} />
            <Route path="/fz250" element={<Fz250 />} />
            <Route path="/fz150" element={<Fz150 />} />
            
            <Route path="/xtz150" element={<Xtz150 />} />
            <Route path="/xpulse200" element={<Xpulse200 />} />
            <Route path="/dominar400" element={<Dominar400 />} />
            <Route path="/kymcoAgility125" element={<KymcoAgility125 />} />
            <Route path="/xre300" element={<Xre300 />} />
            <Route path="/bws125" element={<Bws125 />} />
            <Route path="/pcx150" element={<Pcx150 />} />
            
            <Route path="/gixxer250" element={<Gixxer250 />} />
            <Route path="/kymcoTwist125" element={<KymcoTwist125 />} />
           
            <Route path="/reservation" element={<Reservation />} />
            <Route path="/condiciones4rent" element={<Condiciones4Rent />} />
            <Route path="/requisitos4rent" element={<Requisitos4Rent />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/variedadMotos" element={<VariedadMotos />} />
            <Route path="/asesores" element={<Asesores />} />
            
            {/* Rutas para Spaces */}
            <Route path="/girardod" element={<Girardod />} /> 
            <Route path="/parques" element={<Parques />} /> 
            <Route path="/laguna" element={<Laguna />} /> 
            <Route path="/chiflón" element={<Chiflón />} />
            <Route path="/centro" element={<Centro />} />
            <Route path="/jardin" element={<Jardin />} />
            <Route path="/ruiz" element={<Ruiz />} />
            <Route path="/caño" element={<Caño />} />
            <Route path="/villa" element={<Villa />} />
            {/* Ruta comodín al final */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
        <FloatingWhatsApp phoneNumber="3154573482" />
        <Footer />
        <CookieConsent />
      </div>
    </Router>
  );
}

export default App;
