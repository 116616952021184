// src/components/CookieConsent.js

import { useCookies } from 'react-cookie';
import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  max-width: 800px;
  margin: 0 auto;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;

  @media (max-width: 600px) {
    flex-direction: column;
    text-align: center;
  }
`;

const Text = styled.p`
  margin: 0;
  font-size: 14px;
  color: #333;
  flex: 1;
`;

const Link = styled.a`
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Button = styled.button`
  border-radius: 0.375rem;
  border: 2px solid #fcf75e;
  background-color: #fcf75e;
  color: black !important;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  z-index: 2;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  margin-left: 15px;

  &:hover {
    background-color: #e0e05e;
    border-color: #e0e05e;
  }

  @media (max-width: 600px) {
    margin-left: 0;
    margin-top: 10px;
  }
`;

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(['cookieConsent']);

  const giveCookieConsent = () => {
    // Establece la cookie con una expiración de 1 año
    const oneYear = 365 * 24 * 60 * 60;
    setCookie('cookieConsent', true, { path: '/', maxAge: oneYear });
  };

  // Si la cookie de consentimiento ya está establecida, no renderizas el componente
  if (cookies.cookieConsent) {
    return null;
  }

  return (
    <Container role="dialog" aria-live="polite">
      <Text>
        Usamos cookies para mejorar tu experiencia de usuario. Al usar nuestro sitio web,
        aceptas el uso de cookies.{' '}
        <Link href="/privacy-policy">Aprende más.</Link>
      </Text>
      <Button onClick={giveCookieConsent} aria-label="Aceptar uso de cookies">
        Aceptar
      </Button>
    </Container>
  );
};

export default CookieConsent;
